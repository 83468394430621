





























































































































































































































































/* Override the ayko styles */
#app a:not(.menu-link):not(.action):not(.link):hover span[data-v-429b93ac] {
  color: #fff;
}
.rc-height[data-v-429b93ac] {
  height: 50vh;
}
.rc-heading-one[data-v-429b93ac] {
  font-size: 28px;
}
.rc-heading-two[data-v-429b93ac] {
  font-size: 28px;
}
.rc-overlay[data-v-429b93ac] {
  top: 70%;
  left: 50%;
  width: 90vw;
  text-align: left;
  position: absolute;
  transform: translate(-50%, -50%);
}
/*
  Tailwind Breakpoints
  * sm	640px
  * md	768px
  * lg	1024px
  * xl	1280px
  * 2xl 1536px
*/

/* MOBILE PORTRAIT */
@media (max-width: 639px) and (orientation: portrait) {
.rc-height[data-v-429b93ac] {
    height: 50vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 28px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 28px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}
/* MOBILE LANDSCAPE */
@media (min-width: 640px) and (max-width: 932px) and (orientation: landscape) {
.rc-height[data-v-429b93ac] {
    height: 70vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 28px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 28px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}
/* TABLET PORTRAIT */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
.rc-height[data-v-429b93ac] {
    height: 90vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 38px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 38px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}
/* TABLET LANDSCAPE */
@media (min-width: 1024px) and (orientation: landscape) {
.rc-height[data-v-429b93ac] {
    height: 80vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 38px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 38px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}
/* LARGE TABLET PORTRAIT */
@media (min-width: 1024px) and (orientation: portrait) {
.rc-height[data-v-429b93ac] {
    height: 90vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 38px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 38px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}
/* LARGE TABLET LANDSCAPE */
@media (min-width: 1366px) and (orientation: landscape) {
.rc-height[data-v-429b93ac] {
    height: 50vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 38px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 38px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}

/* LARGE DESKTOP */
@media (min-width: 1367px) {
.rc-height[data-v-429b93ac] {
    height: 70vh;
}
.rc-heading-one[data-v-429b93ac] {
    font-size: 70px;
}
.rc-heading-two[data-v-429b93ac] {
    font-size: 70px;
}
.rc-overlay[data-v-429b93ac] {
    top: 70%;
}
}

/* EXTRA LARGE DESKTOP */
@media (min-width: 1536px) {
.rc-height[data-v-429b93ac] {
    height: 80vh;
}
}
